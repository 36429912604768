<template>
  <v-app>
    <div class="home">
        <div class="row">
            <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff;  padding-left:40px">
                <span style="color:#777; font-size:18px;padding:10px;">My Setting </span>
            </div>
        </div>
             <div style="background: #f5f5f5; margin-top:150px; " class=" container-fluid row">
             <div style="background-color:#F7DBCC; height: 350px;" class="col-md-3">
    <div id="avatar" style="margin:auto"></div>
    <!-- {{staffData}} -->
    <div class="col-md-12" style="margin-top:-20px; text-align:center; font-weight:600; color:#555"><i class="fa fa-book"></i>
    {{staffData.prefix}} {{staffData.surname}} {{staffData.name}}
    </div>
    <div class="col-md-12" style="margin-top:-30px; text-align:center; font-weight:; color:#555"><i class="fa fa-book"></i>
    {{staffData.cadre}} 
    </div>
    <div class="col-md-12" style="margin-top:-20px; text-align:center; font-weight:600; color:#555"><i class="fa fa-book"></i>
    {{staffData.email}}
    </div>
    <div class="col-md-3" style="background-color:#4BB543; margin:auto; border-radius: 50px;padding:3px !important; text-align: center; font-size:14px; color:#fff; font-weight: 600; margin-top:-10px">{{staffData.active}}
    <!-- <div class="dotactive" style=""></div> -->
    
    </div>
    
             </div>
             <div class="col-md-9">
    <div style="background-color:#fff; heigh:auto; min-height: 320px;" class="col-md-12">
    <div class="col-lg-12" v-if="staffData.active == 'Active'">
                        <div class="mt-1 ml-0 pl-0 container wrapper">
                            <div class="pl-4 ">
                    
                        <v-tabs background-color="white">
                            <v-tab>My Assigned Menus</v-tab>
                            <v-tab>Notifications</v-tab>
                            <v-tab>Security</v-tab>
                            <v-tab-item style="margin-top:20px">
                                <v-alert color="primary" border="left" dark prominent>
                            <p><strong>These are the list of menus assigned to you based on programs as well as the department(s) you can administer.</strong> <br></p>
                             <p>You can also set a default menu you will want to be navigated to any time you log in by clicking on set befault by the menu listing.</p>
                            
                        </v-alert>
                                <v-tabs background-color="white">
                                    
                                    <v-tab v-for="(program,i) in programNames" :key="i">{{program}}</v-tab>

                                    <v-tab-item>
                                      
                                    <div style="max-height:600px;overflow:scroll">
                                        <div v-for="(menu, i) in  myMenus" :key="i" class="my-7">
                                            <h4 style="color:#555">{{menu.menu_name}}</h4>

                                            <v-data-table v-model="selected1" :single-select="false" :headers="headers"
                                                :items="menu.sub_menu" item-key="id"  :hide-default-footer="true" class="elevation-0">

                                                <template v-slot:item.default="{item}">
                                                    <span v-if="item.default == true"><v-btn x-small color="success">Default Menu </v-btn></span>
                                                    <span v-if="item.default == false || !item.default"><v-btn x-small @click="setDefault(item,menu)">Set Default</v-btn></span>

                                                </template>
                                               
                                                <template v-slot:item.department_assigned="{item}">
                                                    <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                    <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                                </template>
      
                                            </v-data-table>
                                        </div>
                                        
                                        </div>
                                        <div class="col-md-12 mt-2 mb-0">
                                      
            </div>
                                    </v-tab-item>
                
                                    <!-- <v-tab-item v-if="Program1">
                                      
                                    <div style="max-height:600px;overflow:scroll">
                                        <div v-for="(menu, i) in  Program1.menus" :key="i" class="my-7">
                                            <h4 style="color:#555">{{menu.menu_name}}</h4>

                                            <v-data-table v-model="selected1" :single-select="false" :headers="headers"
                                                :items="menu.sub_menu" item-key="id"  :hide-default-footer="true" class="elevation-0">
                                               
                                                <template v-slot:item.department_assigned="{item}">
                                                    <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                    <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                                </template>
      
                                            </v-data-table>
                                        </div>
                                        
                                        </div>
                                        <div class="col-md-12 mt-2 mb-0">
                                      
            </div>
                                    </v-tab-item> -->
    
                                    <!-- <v-tab-item v-if="Program2">
                                        <div v-for="(menu, i) in  Program2.menus" :key="i" class="my-7">
                                            <h4>{{menu.menu_name}}</h4>
                                            <v-data-table v-model="selected2" :single-select="false" :headers="headers"
                                                :items="menu.sub_menu" item-key="id" show-select class="elevation-1">
    
                                                <template v-slot:item.action="{ item }">
                                                    <v-icon class="mr-2" @click="assignDepartment(item, Program2, selected2)">
                                                        mdi-spellcheck
                                                    </v-icon>
                                                </template>
                                                <template v-slot:item.department_assigned="{item}">
                                                    <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                    <span>{{ ` ${item.department_assigned.map(el => el.text) }`}}</span>
                                                </template>
                                            </v-data-table>
                                        </div>
                                        <div class="py-5">
                                            <button class="save_button"
                                                @click="submit(Program2.program, selected2)">Save</button>
                                        </div>
                                    </v-tab-item>
    
                                    <v-tab-item v-if="Program3">
                                        <div v-for="(menu, i) in  Program3.menus" :key="i" class="my-7">
                                            <h4>{{menu.menu_name}}</h4>
                                            <v-data-table v-model="selected3" :single-select="false" :headers="headers"
                                                :items="menu.sub_menu" item-key="id" show-select class="elevation-1">
    
                                                <template v-slot:item.action="{ item }">
                                                    <v-icon class="mr-2" @click="assignDepartment(item, Program3,selected3)">
                                                        mdi-spellcheck
                                                    </v-icon>
                                                </template>
                                                <template v-slot:item.department_assigned="{item}">
                                                    <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                    <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                                </template>
                                            </v-data-table>
                                        </div>
                                        <div class="py-5">
                                            <button class="save_button"
                                                @click="submit(Program3.program, selected3)">Save</button>
                                        </div>
                                    </v-tab-item>
    
                                    <v-tab-item v-if="Program4">
                                        <div v-for="(menu, i) in  Program4.menus" :key="i" class="my-7">
                                            <h4>{{menu.menu_name}}</h4>
                                            <v-data-table v-model="selected4" :single-select="false" :headers="headers"
                                                :items="menu.sub_menu" item-key="id" show-select class="elevation-1">
    
                                                <template v-slot:item.action="{ item }">
                                                    <v-icon class="mr-2" @click="assignDepartment(item, Program4, selected4)">
                                                        mdi-spellcheck
                                                    </v-icon>
                                                </template>
                                                <template v-slot:item.department_assigned="{item}">
                                                    <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                    <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                                </template>
                                            </v-data-table>
                                        </div>
                                        <div class="py-5">
                                            <button class="save_button"
                                                @click="submit(Program4.program, selected4)">Save</button>
                                        </div>
                                    </v-tab-item>
    
                                    <v-tab-item v-if="Program5">
                                        <div v-for="(menu, i) in  Program5.menus" :key="i" class="my-7">
                                            <h4>{{menu.menu_name}}</h4>
                                            <v-data-table v-model="selected5" :single-select="false" :headers="headers"
                                                :items="menu.sub_menu" item-key="id" show-select class="elevation-1">
    
                                                <template v-slot:item.action="{ item }">
                                                    <v-icon class="mr-2" @click="assignDepartment(item, Program5, selected5)">
                                                        mdi-spellcheck
                                                    </v-icon>
                                                </template>
                                                <template v-slot:item.department_assigned="{item}">
                                                    <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                    <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                                </template>
                                            </v-data-table>
                                        </div>
                                        <div class="py-5">
                                            <button class="save_button"
                                                @click="submit(Program5.program, selected5)">Save</button>
                                        </div>
                                    </v-tab-item>
    
                                    <v-tab-item v-if="Program6">
                                        <div v-for="(menu, i) in  Program6.menus" :key="i" class="my-7">
                                            <h4>{{menu.menu_name}}</h4>
                                            <v-data-table v-model="selected6" :single-select="false" :headers="headers"
                                                :items="menu.sub_menu" item-key="id" show-select class="elevation-1">
    
                                                <template v-slot:item.action="{ item }">
                                                    <v-icon class="mr-2" @click="assignDepartment(item, Program6, selected6)">
                                                        mdi-spellcheck
                                                    </v-icon>
                                                </template>
                                                <template v-slot:item.department_assigned="{item}">
                                                    <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                    <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                                </template>
                                            </v-data-table>
                                        </div>
                                        <div class="py-5">
                                            <button class="save_button"
                                                @click="submit(Program6.program, selected6)">Save</button>
                                        </div>
                                    </v-tab-item>
    
                                    <v-tab-item v-if="Program7">
                                        <div v-for="(menu, i) in  Program7.menus" :key="i" class="my-7">
                                            <h4>{{menu.menu_name}}</h4>
                                            <v-data-table v-model="selected7" :single-select="false" :headers="headers"
                                                :items="menu.sub_menu" item-key="id" show-select class="elevation-1">
    
                                                <template v-slot:item.action="{ item }">
                                                    <v-icon class="mr-2" @click="assignDepartment(item, Program7, selected7)">
                                                        mdi-spellcheck
                                                    </v-icon>
                                                </template>
                                                <template v-slot:item.department_assigned="{item}">
                                                    <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                    <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                                </template>
                                            </v-data-table>
                                        </div>
                                        <div class="py-5">
                                            <button class="save_button"
                                                @click="submit(Program7.program, selected7)">Save</button>
                                        </div>
                                    </v-tab-item>
    
                                    <v-tab-item v-if="Program8">
                                        <div v-for="(menu, i) in  Program8.menus" :key="i" class="my-7">
                                            <h4>{{menu.menu_name}}</h4>
                                            <v-data-table v-model="selected8" :single-select="false" :headers="headers"
                                                :items="menu.sub_menu" item-key="id" show-select class="elevation-1">
                                                <template v-slot:item.action="{ item }">
                                                    <v-icon class="mr-2" @click="assignDepartment(item, Program8, selected8)">
                                                        mdi-spellcheck
                                                    </v-icon>
                                                </template>
                                                <template v-slot:item.department_assigned="{item}">
                                                    <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                    <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                                </template>
                                            </v-data-table>
                                        </div>
                                        <div class="py-5">
                                            <button class="save_button"
                                                @click="submit(Program8.program, selected8)">Save</button>
                                        </div>
                                    </v-tab-item>
    
                                    <v-tab-item v-if="Program9">
                                        <div v-for="(menu, i) in  Program9.menus" :key="i" class="my-7">
                                            <h4>{{menu.menu_name}}</h4>
                                            <v-data-table v-model="selected9" :single-select="false" :headers="headers"
                                                :items="menu.sub_menu" item-key="id" show-select class="elevation-1">
    
                                                <template v-slot:item.action="{ item }">
                                                    <v-icon class="mr-2" @click="assignDepartment(item, Program9, selected9)">
                                                        mdi-spellcheck
                                                    </v-icon>
                                                </template>
                                                <template v-slot:item.department_assigned="{item}">
                                                    <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                    <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                                </template>
                                            </v-data-table>
                                        </div>
                                        <div class="py-5">
                                            <button class="save_button"
                                                @click="submit(Program9.program, selected9)">Save</button>
                                        </div>
                                    </v-tab-item>
    
                                    <v-tab-item v-if="Program10">
                                        <div v-for="(menu, i) in  Program10.menus" :key="i" class="my-7">
                                            <h4>{{menu.menu_name}}</h4>
                                            <v-data-table v-model="selected10" :single-select="false" :headers="headers"
                                                :items="menu.sub_menu" item-key="id" show-select class="elevation-1">
    
                                                <template v-slot:item.action="{ item }">
                                                    <v-icon class="mr-2" @click="assignDepartment(item, Program10,selected10)">
                                                        mdi-spellcheck
                                                    </v-icon>
                                                </template>
                                                <template v-slot:item.department_assigned="{item}">
                                                    <span v-if="item.department_assigned.length == 0">No Departments</span>
                                                    <span>{{ `${item.department_assigned.map(el => el.text) }`}}</span>
                                                </template>
                                            </v-data-table>
                                        </div>
                                        <div class="py-5">
                                            <button class="save_button"
                                                @click="submit(Program10.program, selected10)">Save</button>
                                        </div>
                                    </v-tab-item> -->
    
                                </v-tabs>
    </v-tab-item>
    </v-tabs>
                            </div>
                        </div>
                    </div>
              
                    </div>
                    </div>
                    <v-dialog persistent v-model="showAddDepartment" max-width="500px">
                <v-card>
                    <v-card-title style="padding-bottom:0 !important; margin:0 !important">
                        Set default menu
                        <v-spacer></v-spacer>
                    <span style="color: red; cursor: pointer; font-size: 25px" @click="closeModal"><v-icon>mdi-close-circle</v-icon></span>
                    </v-card-title>
                    <!-- <div style="display: flex; justify-content: space-between;">
                        <h6 class="title-h5 mb-4">Assign Departments to {{menu.menu_name}}</h6>
                        <span style="color: red; cursor: pointer; font-size: 25px" @click="close"><v-icon>mdi-close-circle</v-icon></span>
                    </div> -->
            <v-divider style="margin-top:15px"></v-divider>
                    <v-card-text>

                        <v-alert color="warning" border="left" dark prominent class="mt-6">
                           
                           You are about to make   <strong>{{ menuDetails.menu_name }} > {{submenuDetails.menu_name}}</strong> your default menu for Undergradute regular program. This means that when you login in or Switch to Undergradute regular program. Your default landing page will be <strong>{{ menuDetails.menu_name }} > {{submenuDetails.menu_name}}</strong>
                        </v-alert>
                        <!-- <div class="col-12">
                            <v-select outlined required v-model="faculty" :items="faculties" label="Select Faculty" @click=""
                                ></v-select>
                        </div> -->
                        <!-- <div class="col-12" style="margin-top:-20px">
                            <v-combobox :disabled="disabled" v-model="department" :items="departments" label="Select Department" multiple chips>
                            </v-combobox>
                            <v-select outlined v-model="department" required :items="departments" label="Select Department"
                                class="mr-3"></v-select>
                        </div> -->
                         
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
        
              <v-btn v-if="!action"
                color="blue darken-1"
                text
                @click="finalsetDefault(submenuDetails.menu_link)" >
                Set As Default
              </v-btn>
              <v-btn v-else
                color="blue darken-1"
                text
                @click="finalsetDefault(submenuDetails.menu_link)" >
                <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp; Processing
              </v-btn>
              
                   <!-- <div class="col-md-6" style="text-align: right; margin:auto"> -->
                        <!-- <button @click="handleAssignDepartment" class="button-sub2" type="submit">Assign Department</button> -->
                    <!-- </div> -->
                    </v-card-actions>
                </v-card>
            </v-dialog>



            <v-dialog persistent v-model="showtip" max-width="700px">
                <v-card>
                    <v-card-title style="padding-bottom:0 !important; margin:0 !important">
                        Default menu
                        <v-spacer></v-spacer>
                    <span style="color: red; cursor: pointer; font-size: 25px" @click="closeModalInfo"><v-icon>mdi-close-circle</v-icon></span>
                    </v-card-title>
                    <!-- <div style="display: flex; justify-content: space-between;">
                        <h6 class="title-h5 mb-4">Assign Departments to {{menu.menu_name}}</h6>
                        <span style="color: red; cursor: pointer; font-size: 25px" @click="close"><v-icon>mdi-close-circle</v-icon></span>
                    </div> -->
            <v-divider style="margin-top:15px"></v-divider>
                    <v-card-text>

                        <v-alert color="error" border="left" dark prominent class="mt-6">
                          <strong> You are seeing this page because you have not set any default menu for Undergradute Regular program. You can set a default menu by clicking on SET DEFAULT on the menu list assigned to you. </strong>
                        </v-alert>                         
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>   
                   <!-- <div class="col-md-6" style="text-align: right; margin:auto"> -->
                        <!-- <button @click="handleAssignDepartment" class="button-sub2" type="submit">Assign Department</button> -->
                    <!-- </div> -->
                    <v-btn v-if="!action"
                color="blue darken-1"
                text
                @click="closeModalInfo" >
                Close
              </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


    </div>
    
        </div>
        </v-app>
    </template>
    
    <script>
    
    /**
     * 
     * TODO : Filter departments when faculty is selected based on the department for programs. already we have the program in gloabalSelectedProgramId
     * 
     */
    
    import axios from 'axios';
    import { exit } from 'process';
    import Swal from 'sweetalert2';
    // import {} from 'uuid';
    import { allDepartments, allFaculties,getCookie } from "@Appmart/utility";

    export default {
        data() {
            return {
                action:false,
                    tab: "password",
                showAddDepartment: false,
                showtip:false,
                gloabalSelectedProgramId :'',
                myMenus:[],
                menuDetails:'',
                submenuDetails:'',

                // desserts: null,
                Program1: null,
                Program2: null,
                Program3: null,
                Program4: null,
                Program5: null,
                Program6: null,
                Program7: null,
                Program8: null,
                Program9: null,
                Program10: null,
                disabled: true,
                programNames: [],
                headers: [
                    // {
                    //     text: 'Menu',
                    //     value: 'menu'
                    // },
                    {
                        text: 'Menu Name',
                        value: 'menu_name'
                    },
                    // {
                    //     text: '',
                    //     value: ''
                    // },
                    // {
                    //     text: 'Assigned Department(s)',
                    //     value: 'department_assigned'
                    // },
                    {
                        text: '',
                        value: 'default'
                    }
                ],
                selectionType: "leaf",
                selected: [],
                programMenus: [],
                desserts: [],
                staffData: {},
                MenuArr: [],
                department: '',
                faculty: '',
                facultyHolder: [],
                departments: [],
                departmentsHolder: [],
                menu: {},
                selectectedMenu: null,
                arrModel: null,
                activeItem: null
            }
        },
      
        beforeMount() {
            // this.fetchMenus() 
            // this.fetchProgram()
            this.fetchMenusByProgram();
    
        },
        async mounted() {
            console.log('checker',`${this.$logedIndata}` );
            this.staffData = (JSON.parse(`${this.$logedIndata}`))
        },
        methods: {



            setDefault(x,y){
                console.log(x);
                this.showAddDepartment = true
this.submenuDetails = x
this.menuDetails = y
            },

            async finalsetDefault(x){
                this.action=true
                try {
            console.log(x);
                const data ={
                    menuLink:x,
                    staffId:this.staffData.staffid
                }
                var url = `${this.$appUrl}/setDefault`;
                    const res = await axios.post(url, data);
                    if(res.data.code == 's200'){
                        this.closeModal();
                        this.filterDefult(x)
                        Swal.fire({
                            icon: 'success',
                            title: 'Default Menu',
                            text: 'Default Menu Set Successfuly'
                        })
                    }
                    this.action = false
                } catch (error) {
                    this.action=false
                }
            },

            closeModal() {
                this.showAddDepartment = false;
            },

            closeModalInfo(){
                this.showtip = false
            },
            
           filterDefult(x){
            this.myMenus.forEach(element => {
    element.sub_menu.forEach(lp =>{
        if(lp.default == true){
            lp.default = false
        }
        if(lp.menu_link == x){
            lp.default = true
        }
    })
});
           },

           async fetchAssignedMenus(){
            console.log(this.staffData);
                try {
                    var url = 'https://appcentral.unizik.edu.ng/v1/admin/myMenus';
                        // var url = `${this.$appUrl}/myMenus`;this.staffData._id
                        const res = await axios.post(url, {staffId:this.staffData.staffid ,channel: "userExec",programExec:"6176addadfe4749cf0a90957"});    
 this.myMenus = res.data.message.data
    if(this.staffData.default_menu.length>0){
 this.filterDefult( this.staffData.default_menu[0].subMenuLink);
    }else{
        this.showtip=true
        this.filterDefult('');
    }
                } catch (error) {
                    console.log(error);
                }
            },  

            async fetchMenusByProgram() {
                try {
                var url = `${this.$appUrl}/fetchProgramMenu`;
                    const res = await axios.get(url);
                    if (res.data.code == 's200') {
                        this.programNames = res.data.message.programNames;
                        // res.data.message.data.forEach(menu => {
                        //     // console.log('Menus', menu)
                        //     menu.menus.forEach(men => {
                        //         men.sub_menu.forEach((sub, i) => {
                        //             sub['department_assigned'] = [];
                        //             sub['id'] = `${i+1}${sub.menu_name.split(' ').join('').toLowerCase()}${men._id}`
                        //         })
                        //     })
                        // });
    
                        this.programMenus = res.data.message.data;
    
                        // for (let i = 0; i < this.programMenus.length; i++) {
                        //     console.log('Program' + ' ' + i, this.programMenus[i])
                        //     this[`Program${i + 1}`] = this.programMenus[i];
    
                        // }
                        console.log('Program Menu', this.programMenus)
                        this.fetchAssignedMenus();
    
    
                    }
                } catch (error) {
                    console.log(error);
                }
            },


        }
    }
    </script>
    
    <style lang="scss" scoped>
    .btn-bot{
       position:absolute; 
       margin-left:-35px;
       left:35%;
       bottom:10px;
    }
    .label {
        padding: 20px 0;
        display: flex;
    
        h4 {
            font-family: Proxima Nova;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: #4f4f4f;
            padding-left: 40px;
        }
    
        button {
            width: 45px;
            height: 45px;
            background: #d6ecf5;
            border-radius: 3px;
        }
    }
    
    .save_button {
        padding: 15px;
        width: 149px;
        height: 45px;
        background: #006794;
        border-radius: 3px;
    
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        align-items: center;
        text-align: center;
        color: #ffffff;
    }
    
    .contain {
        width: 100%;
        height: auto;
        background: #ffffff;
    }
    
    .menu_wrap {
        max-height: 500px;
        overflow-y: scroll;
    }
    
    .menu_wrap::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }
    
    .menu_wrap::-webkit-scrollbar {
        width: 8px;
        background-color: #F5F5F5;
    }
    
    .menu_wrap::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #a7a7a7;
    }
    .button-sub2 {
        padding: 15px;
        height: auto;
        display: block;
        width: 100%;
        background: #006794;
        border-radius: 3px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #FFFFFF;
    }
    .bio_details {
        h6 {
            margin-top: 20px;
    
            strong {
                font-family: Proxima Nova;
                font-style: normal;
                font-weight: bold;
                font-size: 20px;
                line-height: 114%;
                text-align: center;
                color: #4f4f4f;
            }
        }
    
        h5 {
            margin-top: 19px;
    
            span {
                font-family: Proxima Nova;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 114%;
                color: #828282;
            }
        }
    }
    .setup_tap {
        border-left: 1px solid #e5e5e5;
    }
    #avatar {
        /* This image is 687 wide by 1024 tall, similar to your aspect ratio */
        background-image: url('../assets/user.svg');
        
        /* make a square container */
        width: 150px;
        height: 150px;
    
        /* fill the container, preserving aspect ratio, and cropping to fit */
        background-size: cover;
    
        /* center the image vertically and horizontally */
        background-position: top center;
    
        /* round the edges to a circle with border radius 1/2 container size */
        border-radius: 50%;
        margin-bottom:20px !important
    }
    </style>
    