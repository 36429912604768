<template>
  
   <div>
    <!-- <Header @showSideNav="showSideNav" class="sticky-top" /> -->

    <SideNav id="sideNav" :style="'--p-width: ' + navWidth" :tokendecoded="tokendecoded" :myMenus="myMenus" :activeSet="activeSet" :sessionSet="sessionSet"/>
    <main>
      <router-view />
    </main>
   
<div id="app">
  <div v-if="showModal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" @click="showModal = false">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Modal body text goes here.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</div>


  </div>
</template>

<script>
import Vue from 'vue';
import SideNav from "@/components/SideNav.vue";
import {parseJwt,getCookie,menusAsigned} from '@Appmart/utility'

import axios from "axios"
export default {
  name: "SideBar",
props:['tokendecoded','myMenus','activeSet','sessionSet'],
 components: {
    SideNav,
  },
 data() {
    return {
      tokendecoded:"",myMenus:"", showModal:false,

    }
  },

  beforeMount(){
   this.getIntroMenu()
},
methods: {

  async getIntroMenu(){
    //check if cookie is set
let name = getCookie("-X_Zka");

//if not set, send back to login
    if(!name){
  window.location.href =`${window.location.origin}/#/auth/v2authsource_05082022`;
    }
    /// decode the jst token
  this.tokendecoded = parseJwt(name);

  console.log(this.tokendecoded);
   
   Vue.prototype.$logedIndata = JSON.stringify(this.tokendecoded);
   
//////// check the active settings
const url = process.env.VUE_APP_DASHBOARD_V1_API_ENDPOINT+`/activeset`;
    await axios.post(url,{
                        staffId: this.tokendecoded.staffid
                    }
                    ).then(response => {
                if(response.data.code =='00'){
                this.activeSet = response.data.data;
                this.sessionSet = response.data.sessionData;
                //to.params.response = response.data;
                }
              }).catch(e => {
/// Write a here that catches this error and writes a mail
              })
console.log(this.activeSet);
/**
 * To do
 * Specify what will happen if the active set does not return any value.
 * note that at the back if the active set is not stored. it automatically creates one. 
 * so checking this here is for precautionary measures because this values is sent to the myMenus api call bellow
 * 
 */

 
  // call for the staff menu

  const activeDepartments = await menusAsigned('userExec',this.activeSet.program._id,this.tokendecoded.staffid).then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
  alert('Something went wrong')
  next(false)
})
if(activeDepartments.data.message.data){
   const arrangeMenu = 
                this.myMenus = activeDepartments.data.message.data;
                //to.params.response = response.data;
                }
// }
  // const url = process.env.VUE_APP_AUTH_V1_API_TEST_ENDPOINT+'/myMenus';

  //   axios.post(url,{
  //                       channel:'userExec',
  //                       programExec: this.activeSet.program._id,
  //                       staffId: this.tokendecoded.staffid
  //                   }
  //                   ).then(response => {
  //               if(response.data.code =='00'){
  //                 const arrangeMenu = 
  //               this.myMenus = response.data.message.data;
  //               //to.params.response = response.data;
  //               }
  //             }).catch(e => {
  //             console.log(e);
  //             })

        if(this.activeSet ==null || this.activeSet.program==''){      
  this.showModal=true
}


      
  },

    showSideNav() {
      this.navWidth = !this.navWidth ? "18.313rem" : 0;
    },
  },
}

</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
  display: table;
  transition: opacity .9s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}


/* .ps-4 {
  padding-left: 2.188rem !important;
}

h6 {
  font-size: 1rem;
  font-weight: 700;
  color: #fff;
}

small {
  color: #fff;
  font-size: 0.75rem;
  font-weight: 400;
}

.link-item-text {
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
}

.sidebar li .submenu {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.sidebar .nav-link {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
}

.sidebar .nav-link:hover {
  color: #fff;
}
ul img{
  color: #fff !important;
}
.sidebar li .submenu{
  padding-left:30px !important
}
#appf {
  background: red;
  height: 100vh;
  overflow-y: scroll;
}

main {
  margin-left: 0;
}

#sideNav {
  height: calc(100vh);
  width: var(--p-width) !important;
  box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
  position: absolute;
  left: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.5s;
  z-index: 1003;
}

Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #sideNav {
    width: 16rem !important;
  }

  main {
    margin-left: 16rem;
  }
} */
</style>