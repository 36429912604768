<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      hide-overlay
      app
      style="background-color: #ff735e"
      :width="300"
    >
      <v-list-item
        style="
          position: fixed;
          top: 10px;
          background-color: #ff735e;
          border-bottom: solid 1px #ee5c46;
          margin-bottom: 0px !important;
        "
      >
        <v-list-item-content class="pa:0">
          <v-list-item-title
            class="text-h6"
            style="height: 47px; margin: 0; padding: 0"
          >
            <v-row>
              <!-- <div class="col-md-3">
                   <v-img src="../assets/uniziklogo.svg" width="30" class="ma-auto">
                   </v-img>
                        </div> -->
              <div class="col-md-12 text-center">
                <div style="color: #fff; font-size: 18px">
                  <strong>UNIZIK Central Portal</strong>
                </div>
                <div
                  style="font-size: 12px; color: #fff; margin-top: !important"
                >
                  Control Panel
                </div>
              </div>
            </v-row>
          </v-list-item-title>
          <v-divider></v-divider>
          <v-list-item style="padding-top: 10px">
            <v-avatar
              color="grey lighten-1"
              class="mt-4 text-center"
              style="text-align: center; margin: auto"
            >
              <v-icon dark style="font-size: 60px; margin: auto"
                >mdi-account-circle</v-icon
              >

              <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png" width="200"></v-img> -->
            </v-avatar>
          </v-list-item>
          <v-list-item style="color: #fff">
            <v-list-item-content class="text-h6 text-center">
              <v-list-item-title class="text-h6 text-center">
                <strong
                  >{{ tokendecoded.title }} {{ tokendecoded.surname }}
                  {{ tokendecoded.name }}</strong
                >
                <!-- {{userData.surname}} {{userData.names}} -->
              </v-list-item-title>
              {{ tokendecoded.email }}
              <!-- <v-list-item-subtitle>{{userData.email}}</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-list-item-content>
      </v-list-item>
      <div
        style="
          top: 246px !important;
          border-top: solid 1px #ed9082;
          position: fixed;
          width: 100%;
        "
      ></div>
      <div style="margin-top: 246px !important">
        <ul style="">
          <!-- {{myMenus}} -->
          <li
            class="nav-item mt-3"
            v-for="(menulist, index) in myMenus"
            :key="index"
          >
            <a
              class="nav-link"
              data-bs-toggle="collapse"
              :data-bs-target="'#menu' + index"
              href="#"
              style="color: white"
            >
              <v-icon style="color: #fff">mdi-clipboard-check-multiple</v-icon>

              <span
                class="link-item-text mx-3"
                style="color: white; margin-left: 40px"
              >
                <strong>{{ menulist.menu_name }}</strong></span
              >
              <i class="bi small bi-caret-down-fill"></i>
            </a>
            <ul :id="'menu' + index" class="submenu collapse">
              <!-- <li><router-link to="/admissions/dashboard" class="nav-link"> Dashboard </router-link></li> -->
              <!-- <li><a class="nav-link" href="/admissions/upload">Upload Admission</a></li> -->
              <!-- <li><a class="nav-link" href="/admissions/listUpload">List Upload </a></li> -->
              <!-- <li><router-link to="/admissions/approveAdmission" class="nav-link"> Approve Admission </router-link></li> -->
              <li
                v-for="(subMenulist, index) in menulist.sub_menu"
                :key="index"
              >
                <router-link
                  :to="subMenulist.menu_link"
                  class="nav-link"
                  style="color: white; margin-left: 40px" v-if="!subMenulist.nonlistable_menu"
                >
                  {{ subMenulist.menu_name }}
                </router-link>
              </li>
              <!-- <li><router-link to="/admissions/changeofcourse" class="nav-link"> Change of course </router-link></li> -->
            </ul>
          </li>

<div v-if="staffData.lecturer">
          <li
            class="nav-item mt-3"
            v-for="(menulist, index) in academicsMenu"
            :key="index" 
          >
            <a
              class="nav-link"
              data-bs-toggle="collapse"
              :data-bs-target="'#menuacad' + index"
              href="#"
              style="color: white"
            >
              <v-icon style="color: #fff">mdi-book-open-page-variant</v-icon>

              <span
                class="link-item-text mx-3"
                style="color: white; margin-left: 40px"
              >
                <strong>{{ menulist.name }}</strong></span
              >
              <i class="bi small bi-caret-down-fill"></i>
            </a>
            <ul :id="'menuacad' + index" class="submenu collapse" > 
              <!-- <li><router-link to="/admissions/dashboard" class="nav-link"> Dashboard </router-link></li> -->
              <!-- <li><a class="nav-link" href="/admissions/upload">Upload Admission</a></li> -->
              <!-- <li><a class="nav-link" href="/admissions/listUpload">List Upload </a></li> -->
              <!-- <li><router-link to="/admissions/approveAdmission" class="nav-link"> Approve Admission </router-link></li> -->
              <li
                v-for="(subMenulist, index) in menulist.lecturer_sub_menu"
                :key="index"
              >
                <router-link
                  :to="subMenulist.link"
                  class="nav-link"
                  style="color: white; margin-left: 40px" 
                >
                  {{ subMenulist.name }}
                </router-link>
              </li>
              <div v-if="staffData.hod">
              <li  v-for="(subMenulist, index) in menulist.hod_sub_menu"
                :key="index"
              >
                <router-link
                  :to="subMenulist.link"
                  class="nav-link"
                  style="color: white; margin-left: 40px" 
                >
                  {{ subMenulist.name }}
                </router-link>
              </li>
              </div>
              <div v-if="staffData.dean">

              <li
                v-for="(subMenulist, index) in menulist.dean_sub_menu"
                :key="index"
              >
                <router-link
                  :to="subMenulist.link"
                  class="nav-link"
                  style="color: white; margin-left: 40px" 
                >
                  {{ subMenulist.name }}
                </router-link>
              </li>
              </div>

              <!-- <li><router-link to="/admissions/changeofcourse" class="nav-link"> Change of course </router-link></li> -->
            </ul>
          </li>
</div>


          <li style="margin-top:15px">
            <a
              class="nav-link"
              href="#/dashboard/settings"
              style="color: white"
            >
              <v-icon style="color: #fff">mdi-cog</v-icon>

              <span
                class="link-item-text mx-3"
                style="color: white; margin-left: 40px"
              >
                <strong>App Setup</strong></span
              >
            </a>
          </li>

          
        </ul>
      </div>
    </v-navigation-drawer>
    <v-app-bar app color="#fff" dark prominent style="height: 70px !important">
      <!-- <template v-slot:img="{ props }">
            <v-img v-bind="props" gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"></v-img>
        </template> -->

      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        style="color: #666"
      ></v-app-bar-nav-icon>
      <div style="color: #ff735e; margin-top: 10px; font-size: 20px" primary>
        {{ activeSet.program.name }}
        <v-btn x-small style="margin-left: 20px">Switch Program</v-btn>
      </div>
            <v-spacer></v-spacer>

      <div style="color:#ff735e;margin-top:11px; font-size: 15px;" primary>  Active Session : <strong>{{sessionSet.academicYear}} / {{parseInt(sessionSet.academicYear) + parseInt(1)}} &nbsp; </strong></div>
      <div style="color:#ff735e;margin-top:11px; font-size: 15px;" primary>|&nbsp;Active Semester : <strong>{{sessionSet.activeSemester}} </strong></div>


      <v-app-bar-nav-icon style="color: #666" @click="destroyTohome"
        ><v-icon>mdi-power</v-icon></v-app-bar-nav-icon
      ><br />
    </v-app-bar>

   
  </div>
</template>

<script>
import { getCookie } from "@Appmart/utility";

export default {
  name: "SideBar",
  props: {
    tokendecoded: {
      type: String,
    },
    myMenus: {
      type: Array,
    },
    activeSet: {
      type: Object,
      default: { program: "" },
    },
    sessionSet:{
      type: Object,
    }
  },
  data() {
    return {
      drawer: null,
      academicsMenu:[{name:"Academics",lecturer_sub_menu:[{name:"Upload result", link:"/academics/uploadResult"},{name:"My Courses", link:"/academics/courses"}],hod_sub_menu:[{name:"Dept. Result Moderation",link:"/academics/hodmode"}],dean_sub_menu:[{name:"Faculty Result Moderation",link:"/academics/deanmode"}]}],
staffData : JSON.parse(this.$logedIndata)
    };
  },

  mounted() {
    console.log('newinfo',this.staffData);
  },
  methods: {
    setactive(x) {
      console.log(this.x);
    },
    destroyTohome() {
      //ShowActivityLoader()
      document.cookie = "-X_Zka" + "=; Max-Age=-99999999;";
      document.cookie =
        "_-_i_-sz_DS_Ari-Max_mqats676gbk" + "=; Max-Age=-99999999;";
      this.$router.go({ path: "/auth/v2authsource_05082022" });
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
